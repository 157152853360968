import React, { useState, forwardRef, useCallback, useEffect, useContext, useMemo, useRef } from 'react';
import { fetchModelList } from '../api/modelAPI.js';
import { AceEditorComponent } from './AceSpaceBro.js';
import { RenderCopyToClipboardButton } from './copyclip.js';
import DataTable from './ChatDataTable.js';
import { getCookie, setCookie } from '../cookieUtils.js';
import './Dashboard.css';
import { AuthContext } from '../App.js';
import MarkdownRenderer from '../utils/markDownRenderer.js';

export function ChatContainer({
  isDarkMode,
  isMobile,
  windowWidth,
  Ampersandposium,
  display,
  reloadData,
  showDelete,
  userEmail,
  wait,
  styles,
  setNotificationMessage,
}, ref) {
  const { vectorVault } = useContext(AuthContext);
  const [chatMessage, setChatMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isChatLoading, setChatIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [getContext, setGetContext] = useState(getCookie('getContext') === 'true');
  const [nContext, setNContext] = useState(4);
  const [historySearch, setHistorySearch] = useState(false);
  const [model, setModel] = useState(getCookie('model') || 'gpt-4o');
  const [prevModel, setPrevModel] = useState(getCookie('model') || 'gpt-4o');
  const [tempMessage, setTempMessage] = useState('');
  const [models, setModels] = useState([]);
  const [isFlow, setIsFlow] = useState(false);
  const [vaultName, setVaultName] = useState('');
  const [customModelName, setCustomModelName] = useState('');
  const [showCustomModelInput, setShowCustomModelInput] = useState(model === 'finetuned');
  const messageEndRef = React.useRef(null);
  const [scrolls, setScrolls] = useState(0)
  const scrollToBottom = useCallback(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (scrolls === 0){ 
      scrollToBottom();
      setScrolls(1);
    } 
  }, [chatHistory, scrollToBottom]);

  // New state to track code block streaming
  const [streamingCodeBlocks, setStreamingCodeBlocks] = useState({});

  const renderChatMessage = useCallback((message, isUserMessage, index) => {
    return (
      <div
        key={index} 
      >
        {renderChatSegment(message, index, isUserMessage)}
      </div>
    );
  }, [isDarkMode]);
  
  // Memoize heavy computations
  const isFlowState = useMemo(() => {
    if (Ampersandposium){
      return Ampersandposium.startsWith('Flow: ');
    }
  }, [Ampersandposium]);
  
  useEffect(() => {
    if (isFlowState) {
      setIsFlow(true);
      setVaultName(Ampersandposium.substring('Flow: '.length));
    } else {
      setIsFlow(false);
    }
  }, [isFlowState, Ampersandposium]);

  useEffect(() => {
    setCookie('getContext', getContext)
  }, [getContext]);

  // Implement lazy loading for chat history
  useEffect(() => {
    const loadChatHistory = async () => {
      const storedChatHistory = localStorage.getItem('chatHistory');
      if (storedChatHistory) {
        try {
          const parsedHistory = JSON.parse(storedChatHistory);
          const CHUNK_SIZE = 20;
          
          for (let i = 0; i < parsedHistory.length; i += CHUNK_SIZE) {
            const chunk = parsedHistory.slice(i, i + CHUNK_SIZE);
            setChatHistory(prev => [...prev, ...chunk]);
            // Small delay to prevent UI blocking
            await new Promise(resolve => setTimeout(resolve, 0));
          }
        } catch (error) {
          console.error('Failed to parse stored chat history:', error);
          setChatHistory([]);
        }
      }
    };

    loadChatHistory();
  }, []);

  // Debounced chat history save
  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      if (chatHistory.length > 0) {
        localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
      }
    }, 1000);

    return () => clearTimeout(saveTimeout);
  }, [chatHistory]);

  useEffect(() => {
    const loadModels = async () => {
      try {
        const fetchedModels = await fetchModelList(userEmail);
        setModels(fetchedModels);
      } catch (error) {
        console.error('Failed to fetch models:', error);
      }
    };
    loadModels();
  }, [userEmail]);

  const handleModelChange = useCallback((e) => {
    const selectedModel = e.target.value;
    setModel(selectedModel);
    setShowCustomModelInput(selectedModel === 'finetuned');
    if (selectedModel !== 'finetuned') {
      setCustomModelName('');
    }
  }, []);

  const getSelectedModel = useCallback(() => {
    return model === 'finetuned' ? customModelName.trim() : model;
  }, [model, customModelName]);
  
  const renderChatSegment = useCallback((segment, index, isUserMessage) => {
    const isFullCodeBlock = /```[\s\S]*?```/.test(segment);
    
    // Handle streaming blocks that aren't complete yet
    if (streamingCodeBlocks[index] && !isFullCodeBlock) {
      const { language, content, blockStart } = streamingCodeBlocks[index];
      const textBeforeBlock = segment.slice(0, blockStart);
      
      return (
        <div className="message-segments">
          {textBeforeBlock && (
            <div 
              className="message-content"
              style={{
                ...(isUserMessage ? styles.userMessage : styles.botMessage),
                margin: '8px 0'
              }}
            >
              {textBeforeBlock}
            </div>
          )}
          <div
            style={{
              position: 'relative',
              width: `${100 + (1 / (windowWidth / 500)) * 5}%`,
              marginLeft: '-4px',
            }}
          >
            <AceEditorComponent 
              key={`editor-${index}-${content.length}`} 
              code={content} 
              type={language || 'python'} 
              isDarkMode={isDarkMode}
              isStreaming={true}
            />
          </div>
        </div>
      );
    }
    
    // Process completed code blocks
    const parts = [];
    let currentPos = 0;
    const codeBlockRegex = /```(\w+)?\n?([\s\S]*?)```/g;
    let match;
  
    while ((match = codeBlockRegex.exec(segment)) !== null) {
      if (match.index > currentPos) {
        parts.push({
          type: 'text',
          content: segment.slice(currentPos, match.index)
        });
      }
  
      parts.push({
        type: 'code',
        language: match[1] || 'python',
        content: match[2]
      });
  
      currentPos = match.index + match[0].length;
    }
  
    if (currentPos < segment.length) {
      parts.push({
        type: 'text',
        content: segment.slice(currentPos)
      });
    }
  
    if (parts.length === 0) {
      parts.push({
        type: 'text',
        content: segment
      });
    }
  
    return (
      <div className="message-segments">
        {parts.map((part, partIndex) => {
          if (part.type === 'code') {
            return (
              <div
                key={`${index}-${partIndex}`}
                style={{
                  position: 'relative',
                  width: `${100 + (1 / (windowWidth / 500)) * 5}%`,
                  marginLeft: '-4px',
                  marginTop: '20px',
                }}
              >
                <AceEditorComponent 
                  key={`static-editor-${index}-${partIndex}`}
                  code={part.content} 
                  type={part.language} 
                  isDarkMode={isDarkMode}
                  isStreaming={false}
                />
                {RenderCopyToClipboardButton(part.content, isDarkMode, isCopied, setIsCopied, () => {
                  setIsCopied(true);
                  setTimeout(() => setIsCopied(false), 300);
                })}
              </div>
            );
          }
  
          const containsMarkdown = /(^|\n)#{1,6}\s|^\s*[-*]\s|\*\*/.test(part.content);
          return (
            <div
              key={`${index}-${partIndex}`}
              className={`message-content ${containsMarkdown ? 'markdown-content' : ''}`}
              style={{
                ...(isUserMessage ? styles.userMessage : styles.botMessage),
              }}
            >
              {containsMarkdown ? (
                <MarkdownRenderer content={part.content} />
              ) : (
                part.content
              )}
            </div>
          );
        })}
      </div>
    );
  }, [windowWidth, isDarkMode, isCopied, styles, streamingCodeBlocks]);

  
  const sendChatMessage = async () => {
    if (!chatMessage.trim()) return;
    
    const pastChatMessage = chatMessage;
    setChatMessage('');
    setChatIsLoading(true);
    setTempMessage(''); // Reset temp message
    
    // Add the user message immediately
    appendChatMessage(pastChatMessage, true);
    
    const messageConfig = pastChatMessage.trim().endsWith('**') 
      ? { 
          metatag: ['item_id'],
          metatag_prefixes: ['\n**Item ID:'],
          metatag_suffixes: ['\n'],
          returnContext: true 
        }
      : {
          metatag: [],
          metatag_prefixes: [],
          metatag_suffixes: [],
          returnContext: false
        };
  
    const fullHistory = chatHistory
      .map(item => item.isUserMessage ? `User: ${item.message}` : `Response: ${item.message}`)
      .join('\n');
  
    try {
      if (isFlow) {
        await handleFlowMessage(pastChatMessage, fullHistory);
      } else {
        await handleChatMessage(pastChatMessage, fullHistory, messageConfig);
      }
    } catch (error) {
      appendChatMessage(error.message, false);
      setChatIsLoading(false);
    }
  };

  const handleFlowMessage = async (message, history) => {
    await vectorVault.runFlowStream(vaultName, message, history, {
      onMessage: handleStreamMessage,
      onLog: (logData) => console.log('Log:', logData),
      onError: handleError
    });
  };

  const handleChatMessage = async (message, history, config) => {
    const params = {
      vault: vaultName,
      text: message,
      history,
      get_context: getContext,
      n_context: nContext,
      return_context: config.returnContext,
      smart_history_search: historySearch,
      model: getSelectedModel(),
      ...config
    };

    await vectorVault.getChatStream(params, handleStreamMessage);
  };
  
  const appendChatMessage = useCallback((message, isUserMessage) => {
    if (!message.trim()) return; // Don't add empty messages
    
    setChatHistory(prev => {
      const newHistory = [...prev, { message, isUserMessage }];
      localStorage.setItem('chatHistory', JSON.stringify(newHistory));
      return newHistory;
    });
  }, []);

  const handleError = useCallback((error) => {
    appendChatMessage(error.message, false);
    localStorage.setItem(
      'chatHistory',
      JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }])
    );
    setChatIsLoading(false);
  }, [chatHistory]);
  
  
  const handleStreamMessage = useCallback((dataChunk) => {
    if (dataChunk === '!END') {
      setTempMessage('');
      setChatIsLoading(false);
      
      // Only clear streaming blocks if we have complete code blocks
      setChatHistory(prev => {
        const lastMsg = prev[prev.length - 1];
        if (lastMsg && /```[\s\S]*?```/.test(lastMsg.message)) {
          setStreamingCodeBlocks({});
        }
        return prev;
      });
    } else {
      // Only update chatHistory during streaming, remove tempMessage updates
      setChatHistory(prev => {
        const currentIndex = prev.length > 0 && !prev[prev.length - 1].isUserMessage 
          ? prev.length - 1 
          : prev.length;
  
        const updatedMessage = prev.length > 0 && !prev[prev.length - 1].isUserMessage
          ? prev[prev.length - 1].message + dataChunk
          : dataChunk;
  
        // Find all code block markers
        const lastStartIndex = updatedMessage.lastIndexOf('```');
        if (lastStartIndex !== -1) {
          const afterStart = updatedMessage.slice(lastStartIndex + 3);
          const languageMatch = afterStart.match(/^(\w+)?\n/);
          const hasEndMarker = afterStart.includes('```');
  
          if (!hasEndMarker && (!streamingCodeBlocks[currentIndex] || lastStartIndex !== streamingCodeBlocks[currentIndex].blockStart)) {
            // Start new code block
            const language = languageMatch ? languageMatch[1] : 'python';
            const contentStart = lastStartIndex + 3 + (languageMatch ? languageMatch[0].length : 0);
            
            setStreamingCodeBlocks(prev => ({
              ...prev,
              [currentIndex]: {
                language,
                content: updatedMessage.slice(contentStart).trimStart(),
                blockStart: lastStartIndex
              }
            }));
          } else if (streamingCodeBlocks[currentIndex]) {
            // Update existing block
            const { blockStart } = streamingCodeBlocks[currentIndex];
            const currentContent = updatedMessage.slice(blockStart + 3);
            
            if (currentContent.includes('```')) {
              // Block is complete
              const endIndex = currentContent.indexOf('```');
              const finalContent = currentContent.slice(0, endIndex).trimEnd();
              
              setStreamingCodeBlocks(prev => {
                const newBlocks = { ...prev };
                delete newBlocks[currentIndex];
                return newBlocks;
              });
            } else {
              // Update streaming content
              setStreamingCodeBlocks(prev => ({
                ...prev,
                [currentIndex]: {
                  ...prev[currentIndex],
                  content: currentContent.trimStart()
                }
              }));
            }
          }
        }
  
        return prev.length > 0 && !prev[prev.length - 1].isUserMessage
          ? [...prev.slice(0, -1), { message: updatedMessage, isUserMessage: false }]
          : [...prev, { message: updatedMessage, isUserMessage: false }];
      });
    }
  }, [chatHistory, streamingCodeBlocks]);
  
  const clearChat = useCallback(() => {
    setChatHistory([]);
    localStorage.removeItem('chatHistory');
  }, []);

  const handleTextareaKeydown = useCallback((e) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      sendChatMessage();
    }
  }, [sendChatMessage]);

  return (
    <div
      className="response-container"
      id="chat-container"
      style={{ 
        ...styles.responseContainer,
        minHeight: '200px', // Set a minimum height
        display: 'flex',
        padding: isMobile ? '20px 6px' : '20px',
        height: 'auto',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {/* Header with clear button */}
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginBottom: '20px',
      }}>
        <button
          id="clear-chat"
          className="link-button"
          onClick={clearChat}
          style={{ marginRight: isMobile ? '8px' : '2px' }}
        >
          Clear
        </button>
      </div>

      {/* Chat history container */}
      <div style={{ width: '100%', marginBottom: '20px' }}>
        {chatHistory.map((chat, index) => 
          renderChatMessage(chat.message, chat.isUserMessage, index)
        )}
        {isChatLoading && tempMessage && 
          renderChatMessage(tempMessage, false, chatHistory.length)
        }
        <div ref={messageEndRef} />
      </div>

        {isChatLoading && (
          <div
            className="loading-spinner"
            id="loading-spinner"
            style={{
              ...styles.loadingSpinner,
              display: 'block',
              paddingLeft: '20px',
            }}
          >
            <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
              <circle
                cx="25"
                cy="25"
                r="20"
                stroke="#007aff"
                strokeWidth="5"
                fill="none"
                style={styles.loadingSpinnerCircle}
              />
            </svg>
          </div>
        )}

      <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ ...styles.yourmsgLabel }}>
            <label htmlFor="chat-message">Chat:</label>
          </div>
          {!isFlow && (
            <>
              <select
                style={{ marginLeft: '10px', marginTop: '20px' }}
                className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                value={model}
                onChange={handleModelChange}
              >
                {models.map((modelItem) => (
                  <option key={modelItem.model} value={modelItem.model}>
                    {modelItem.model}
                  </option>
                ))}
                <option value="finetuned">Finetuned Model</option>
              </select>
              {showCustomModelInput && (
                <input
                  type="text"
                  placeholder="Enter fine-tuned model name"
                  value={customModelName}
                  onChange={(e) => setCustomModelName(e.target.value)}
                  style={{ ...styles.apiInput, marginLeft: '10px', marginTop: '20px' }}
                />
              )}
            </>
          )}
        </div>
      </div>

      <textarea
        placeholder="Type your message here..."
        id="chat-message"
        rows={isMobile ? '8' : '5'}
        style={{ ...styles.textarea, paddingLeft: '8px' }}
        value={chatMessage}
        onChange={(e) => setChatMessage(e.target.value)}
        onKeyDown={handleTextareaKeydown}
      />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <button id="chat-submit" style={{ ...styles.button }} onClick={sendChatMessage}>
          Send
        </button>
      {!isFlow && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="vault-reply"
              style={{ marginLeft: '20px', marginTop: '10px' }}
              onChange={(e) => setGetContext(e.target.checked)}
              default={getContext}
              title="Check to use Vault context in the reply (default). Uncheck to use the LLM without any Vault context."
            />
            <label
              style={{
                ...styles.text,
                marginLeft: '6px',
                marginTop: '10px',
                fontSize: '.9rem',
              }}
              htmlFor="vault-reply"
              title="Check to use Vault context in the reply (default). Uncheck to use the LLM without any Vault context."
            >
              get context
            </label>
          </div>
        )}
      </div>

      <div style={{ marginTop: '100px' }}></div>

      {display && !isFlow && (
        <DataTable
          Ampersandposium={Ampersandposium}
          reloadData={reloadData}
          showDelete={showDelete}
          userEmail={userEmail}
          isDarkMode={isDarkMode}
          wait={wait}
          styles={styles}
        />
      )}
    </div>
  );
}

export default forwardRef(ChatContainer);